<template>
  <div class="dashboard-main">
    <div @click="handleClose" :class="{ open: isNavOpen }" class="side-overlay">
      <aside class="dashboard-sidebar">
        <div class="logo">
          <img src="../../assets/images/logo.png" alt="Logo" />
        </div>
        <!-- <span class="username">Go &nbsp;<span>John</span></span> -->
        <ul class="link-items">
          <router-link class="nav-link" :to="{ name: 'Dashboard' }">
            <i class="fa-solid fa-gauge"></i
            ><span class="link-text"> Dashboard</span></router-link
          >
          <router-link class="nav-link" :to="{ name: 'Orders' }">
            <i class="fa-solid fa-cart-flatbed"></i
            ><span class="link-text"> Orders</span></router-link
          >
          <router-link class="nav-link" :to="{ name: 'AddFunds' }">
            <i class="fa-solid fa-credit-card"></i>
            <span class="link-text">Add Funds</span></router-link
          >

          <router-link class="nav-link" :to="{ name: 'Services' }">
            <i class="fa-solid fa-list"></i>
            <span class="link-text">Services</span></router-link
          >
          <!-- <router-link class="nav-link" :to="{ name: 'Tickets' }">
            <i class="fa-solid fa-ticket"></i>
            <span class="link-text">Tickets</span></router-link
          >
          <router-link class="nav-link" :to="{ name: 'Referrals' }">
            <i class="fa-solid fa-network-wired"></i>
            <span class="link-text">Referrals</span></router-link
          >
          <router-link class="nav-link" :to="{ name: 'ChildPanel' }">
            <i class="fa-solid fa-briefcase"></i>
            <span class="link-text">Child Panel</span></router-link
          > -->

          <router-link class="nav-link" :to="{ name: 'Settings' }">
            <i class="fa-solid fa-gear"></i
            ><span class="link-text">Settings</span></router-link
          >
          <!-- <router-link class="nav-link" :to="{ name: 'Api' }">
            <i class="fa-solid fa-star-of-life"></i
            ><span class="link-text">Api</span></router-link
          > -->
          <button class="nav-link">
            <i class="fa-solid fa-right-from-bracket"></i
            ><span class="link-text">Logout</span>
          </button>
        </ul>
      </aside>
    </div>
    <section class="main">
      <header class="dashboard-header">
        <span class="hamburger"
          ><i
            class="fa-solid fa-bars-staggered pointer"
            @click="isNavOpen = !isNavOpen"
          ></i>
        </span>
        <div class="dashboard-text">{{ $route.meta.title || "Dashboard" }}</div>
        <div class="account-btn-drop">
          <div class="image">
            <img
              :src="
                user && user.avatar
                  ? user.avatar
                  : `https://avatars.dicebear.com/api/bottts/${Date.now()}.svg`
              "
              alt=""
            />
          </div>
          <!-- <i class="fa-solid fa-caret-down"></i>
          <div v-if="dropOpen" class="drop-down">
            <router-link class="nav-link" :to="{ name: 'ChildPanel' }">
              <i class="fa-solid fa-gear"></i>
              <span class="link-text">Settings</span></router-link
            >

            <router-link class="nav-link" :to="{ name: 'Api' }">
              <i class="fa-solid fa-power-off"></i
              ><span class="link-text">Logout</span></router-link
            >
          </div> -->
        </div>
      </header>
      <div class="route-area-main">
        <div class="scroll-area">
          <transition name="fade" mode="out-in">
            <router-view></router-view>
          </transition>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "Dashboard",
  data() {
    return {
      isNavOpen: false,
      dropOpen: false,
    };
  },
  methods: {
    handleClose(e) {
      if (
        e.target.getAttribute("class") &&
        e.target.getAttribute("class").includes("side-overlay")
      ) {
        this.isNavOpen = false;
      }
    },
    handleDropOpen() {
      this.dropOpen = !this.dropOpen;
    },
  },
  watch: {
    $route() {
      this.isNavOpen = false;
    },
  },
  computed: {
    ...mapState(["user"]),
  },
};
</script>
<style lang="scss">
@import "./index.scss";
</style>
